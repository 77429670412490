<template>
    <v-tabs dark class="request-tab-top">
        <v-tab><div class="text-h7 nav-font">Parts Ordered</div></v-tab>
        <v-tab><div class="text-h7 nav-font">Shipping</div></v-tab>
        <v-tab><div class="text-h7 nav-font">Tracking</div></v-tab>

        <!-- Parts Ordered -->
        <v-tab-item>
            <div
                class="part-order-details"
                style="background-color: rgb(75, 75, 75)"
            >
                <div id="card-elements">
                    <v-data-table
                        id="parts-orders-data-table"
                        dark
                        loading="true"
                        :headers="headers"
                        :items="partOrder"
                        :items-per-page="10"
                    >
                        <template v-slot:[`item.number`]="{ item, index }">
                            {{ index + 1 }}
                        </template>
                    </v-data-table>
                </div>
            </div>
        </v-tab-item>
        <!-- Shipping -->
        <v-tab-item>
            <div
                class="part-order-details"
                style="background-color: rgb(75, 75, 75)"
            >
                <div id="card-elements">
                    <v-row class="mt-1 pa-3">
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .company
                                        "
                                        label="Company"
                                        outlined
                                        hide-details
                                        dense
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.contact
                                                .fullName
                                        "
                                        label="Full Name"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.contact
                                                .email
                                        "
                                        outlined
                                        label="Email"
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.contact
                                                .phone
                                        "
                                        label="Phone"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .line1
                                        "
                                        label="Address Line 1"
                                        outlined
                                        hide-details
                                        dense
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .line2
                                        "
                                        label="Address Line 2"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .city
                                        "
                                        outlined
                                        label="City"
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .state
                                        "
                                        label="State"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .country
                                        "
                                        label="Country"
                                        outlined
                                        hide-details
                                        dense
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].customer.shipping.address
                                                .postalCode
                                        "
                                        label="Postal Code"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-tab-item>
        <v-tab-item>
            <div
                v-if="local[0].tracking.outbound"
                class="part-order-details"
                style="background-color: rgb(75, 75, 75)"
            >
                <div id="card-elements">
                    <v-row class="mt-1 pa-3">
                        <v-col cols="4">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].tracking.outbound.carrier
                                        "
                                        label="Carrier"
                                        outlined
                                        hide-details
                                        dense
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        dark
                                        :value="
                                            local[0].tracking.outbound
                                                .trackingNumber
                                        "
                                        label="Tracking Number"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    />
                                </v-col>
                                <v-col cols="12" style="padding: 20px">
                                    <label style="color: white"
                                        >Tracking URL:</label
                                    ><br />
                                    <a
                                        :href="
                                            local[0].tracking.outbound
                                                .trackingUrl
                                        "
                                        target="_blank"
                                        >{{
                                            local[0].tracking.outbound
                                                .trackingUrl
                                        }}</a
                                    >
                                    <!-- <v-text-field
                                        dark
                                        :value="
                                            local[0].tracking.outbound
                                                .trackingUrl
                                        "
                                        label="Tracking URL"
                                        outlined
                                        dense
                                        hide-details
                                        readonly
                                    /> -->
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div
                v-else
                class="part-order-details"
                style="background-color: rgb(75, 75, 75)"
            >
                <div id="card-elements">
                    <v-col cols="4"
                        ><v-text-field
                            dark
                            value="No tracking information available"
                        ></v-text-field></v-col></div
            ></div>
        </v-tab-item>
    </v-tabs>
</template>

<script>
export default {
    name: 'DetailsTabs',
    props: {
        partOrder: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            snackbar: false,
            snackbarMessage: '',
            local: null,
            batch: null,
            headers: [
                {
                    text: 'Number',
                    align: 'center',
                    sortable: false,
                    value: 'number'
                },
                {
                    text: 'Serial',
                    value: 'unit.serial',
                    sortable: false
                },
                {
                    text: 'Model',
                    value: 'unit.model',
                    sortable: false
                },
                {
                    text: 'Part Category',
                    value: 'category',
                    sortable: false
                },
                { text: 'Part Spec', value: 'partSpec', sortable: false },
                { text: 'Status', value: 'status', sortable: false }
            ]
        }
    },
    watch: {
        partOrder: {
            deep: true,
            immediate: true,
            handler() {
                this.local = this.partOrder
            }
        }
    },
    created() {
        this.test()
    },
    methods: {
        test() {
            console.log(this.local)
        }
    }
}
</script>

<style>
.v-tabs .request-tab-top .theme--dark {
    background-color: transparent !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
    background-color: transparent;
}
</style>
